@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/input-group';

$label-required-margin: 0.15rem;
$label-required-content-color: $primary;

$switch-knob-bg: $white;
$switch-bg: $input-focus-bg;
$switch-active-bg: $switch-active-bg;

// Switch
.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
  padding-left: 2.25rem;

  & .custom-control-label {
    font-size: $fs-sm;
    padding-left: 1.25rem;
    padding-top: 0.45rem;

    &::before {
      border-radius: 1.25rem;
      height: 1.75rem;
      width: 3rem;
    }

    &::after {
      border-radius: 0.85rem;
      height: calc(1.75rem - 4px);
      width: calc(1.75rem - 4px);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.25rem);
  }
}

.custom-control-input + .custom-control-label {
  &::before {
    background-color: $switch-bg;
    border-color: $switch-bg;
  }

  &::after {
    background-color: $switch-knob-bg !important;
  }
}

.custom-control-input:checked + .custom-control-label {
  &::before {
    background-color: $switch-active-bg;
    border-color: $switch-active-bg;
  }

  &::after {
    background-color: $switch-knob-bg;
  }
}

.custom-control-label:hover {
  &::after {
    box-shadow: 0 0 0 9px $gray-355;
  }
}

// Text input
textarea,
input.form-control {
  border-color: $input-bg !important;

  &:hover {
    background-color: $input-hover-bg;
    border-color: $input-hover-bg !important;
  }

  &:focus {
    border-color: $input-focus-bg !important;

    &:hover {
      background-color: $input-focus-bg !important;
    }
  }
}

.form-label {
  color: $gray-370;

  &.required {
    &::after {
      margin-left: $label-required-margin;
      content: '*';
      color: $label-required-content-color;
    }
  }
}

.form-text {
  opacity: 0;

  &.show {
    opacity: 1;
  }
}

.error-text {
  margin-top: 0;
  border-top: 2px solid $primary;
  color: $primary;
}

// Input group
.input-group {
  &-append {
    background-color: #fff;
  }

  &-append span,
  &-prepend span {
    position: relative;
    background-color: $input-bg;
    border: none;
    padding: 0.375rem 1.25rem;

    &::after {
      position: absolute;
      content: ' ';
      height: 60%;
      width: 1px;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &-prepend span::after {
    right: 0 !important;
  }

  &:hover {
    .input-group-append span,
    .input-group-prepend span {
      background-color: $input-hover-bg;
    }
  }

  &:focus-within {
    .input-group-append span,
    .input-group-prepend span {
      background-color: $input-bg;
    }

    &:hover {
      .input-group-append span,
      .input-group-prepend span {
        background-color: $input-hover-bg;
      }
    }
  }

  &:focus {
    input.form-control {
      border-color: $input-focus-bg !important;
    }

    &:hover {
      input.form-control {
        background-color: $input-focus-bg !important;
      }
    }
  }
}

.form-group {
  .invalid-feedback {
    margin-top: 0;
    border-top: 2px solid $primary;
    color: $primary;

    &.feedback-displayed {
      display: block;
      border-top: none;
    }
  }

  .custom-select {
    background: $input-bg url(../images/Chevron.svg) no-repeat right 0.75rem
      center/13px 10px;
  }
}
