/* =============================================================================
* Toyota Font Definitions
* ========================================================================== **/
@font-face {
  font-family: 'Toyota Semibold';
  src: url('../fonts/ToyotaType-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Toyota Icon Restyle';
  src: url('../fonts/Toyota-icon-restyle.woff2') format('woff2');
}

@font-face {
  font-family: 'Toyota Book';
  src: url('../fonts/ToyotaType-Book.woff2') format('woff2');
}

@import url(//fonts.googleapis.com/css?family=Open+Sans);

body {
  font-family: 'Toyota Book', 'Open Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
