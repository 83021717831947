@import 'node_modules/bootstrap/scss/buttons';

$button-radius: $rounded-pill;
$button-height: 2.5rem;

$button-lg-min-width: 10rem;
$button-lg-height: 3rem;

$button-sm-min-width: 6rem;

$button-secondary-bg: $white;
$button-secondary-color: $body-color;
$button-secondary-border: 1px solid $primary;
$button-secondary-bg-hover: $gray-150;

$button-func-bg: $gray-125;
$button-func-bg-hover: $gray-250;
$button-func-bg-click: $gray-350;

.btn {
  border-radius: $button-radius;
  height: $button-height;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;

  &:focus {
    box-shadow: none;
  }

  &-lg {
    min-width: $button-lg-min-width;
    height: $button-lg-height;
    font-size: 1rem;
  }

  &-sm {
    min-width: $button-sm-min-width;
    font-size: 1rem;
  }

  > span:first-child {
    padding: 0 0.25rem 0 0.5rem;
  }

  > span:last-child {
    padding: 0 0.5rem 0 0.25rem;
  }

  &-link {
    color: $text-muted;

    &:hover {
      background-color: $gray-125;
      color: $text-muted;
      text-decoration: none;
    }

    &:focus {
      background-color: $gray-250;
      color: $text-muted;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $button-secondary-bg;
    color: $button-secondary-color;
    border: $button-secondary-border;

    &:hover,
    &:focus,
    &:active {
      background-color: $button-secondary-bg-hover !important;
      color: $button-secondary-color !important;
      border: $button-secondary-border !important;
      box-shadow: none !important;
    }

    svg {
      color: $primary;
    }
  }

  &-functional {
    background-color: $button-func-bg;

    &:hover {
      background-color: $button-func-bg-hover;
    }

    &:focus {
      background-color: $button-func-bg-click;
    }

    svg {
      color: $primary;
    }
  }

  &-icon {
    background-color: $button-func-bg;
    width: 40px;
    padding: 0;

    &:hover {
      background-color: $button-func-bg-hover;
    }

    &:focus {
      background-color: $button-func-bg-click;
    }
  }

  &.dropdown-toggle {
    background-color: transparent !important;
    border: 1px solid $btn-dropdown-border;
    font-size: $fs-lg;
    color: $primary-grey !important;
  }
}
