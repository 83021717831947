.detail-view {
  .detail-view-hero {
    .carousel {
      &-btn,
      &-dot {
        border: none;
        background: transparent;
      }

      &-btn:focus {
        outline: transparent;
      }
    }

    .icons {
      margin-left: -15px;
    }

    .detail-view-accessory {
      position: relative;
      padding-left: 1.75rem;

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .leasing {
    background-color: $page-background;
    margin: 3rem 0;

    .input-group {
      .form-control:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      .input-group-text {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }

      input {
        text-align: center;

        @media only screen and (min-width: 992px) {
          flex: 0 1 auto;
          width: 55px;

          &:last-of-type {
            width: 75px;
          }
        }
      }
    }

    .underline {
      border-radius: 3px;
      background: $primary;
      height: 4px;
      margin-top: 5px;
    }
  }
}
