@import '../../../../assets/scss/variables';

.text {
  &-uppercase {
    text-transform: uppercase;
  }

  &-strong {
    font-weight: $fw-900;
  }

  &-error {
    color: $primary;
  }

  &-disabled {
    color: $gray-360;
  }

  &-info {
    color: $info;
  }

  &-xs {
    font-size: $fs-xs;
  }

  &-sm {
    font-size: $fs-sm;
  }

  &-lg {
    font-size: $fs-lg;
  }

  &-xxl {
    font-size: $fs-xxl;
  }

  &-italic {
    font-style: italic;
  }
}
