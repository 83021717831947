@import '../../assets/scss/variables';

$interest-fs: 2.25rem;
$interest-hybrid-fs: 2.25rem;
$interest-subtext-fs: 0.8rem;

$interest-hybrid-text-fs: 0.8rem;
$interest-hybrid-subtext-fs: 0.7rem;

.price-box {
  display: flex;
  flex-direction: column;
  background-color: #eff0f0;
}

.leasing-interest {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .interest {
    display: inline-block;
    font-size: $interest-fs;
    line-height: 2rem;
    padding-right: 0.5rem;

    span {
      display: inline-block;
      height: $interest-fs;
    }

    &.hybrid.list-icon {
      font-size: $interest-hybrid-fs;
    }

    &-text {
      display: flex;
      flex-direction: column;

      &.hybrid {
        line-height: 1rem;

        &.list-icon {
          line-height: $interest-hybrid-text-fs;
          font-size: $interest-hybrid-text-fs;

          > *:nth-child(2) {
            padding-top: 0.15rem;
            white-space: break-spaces;
            font-size: $interest-hybrid-subtext-fs;
          }
        }
      }

      &-capital {
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      > *:nth-child(2) {
        font-size: $interest-subtext-fs;
      }
    }
  }
}
