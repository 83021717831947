$primary: #ff0022;
$secondary: #000;
$info: #0096e9;

$gray-125: #f1f2f2;
$gray-150: #f5f5f5;
$gray-250: #e5e6e6;
$gray-300: #8b8c90;
$gray-350: #cbcccc;
$gray-355: #cbcccc8f;
$gray-360: #6a6b70;
$gray-370: #494950;
$primary-grey: #282830;

$input-bg: rgba(0, 0, 0, 0.05);
$input-hover-bg: $gray-250;
$input-focus-bg: $gray-350;
$input-focus-box-shadow: none;

$switch-active-bg: $gray-370;

$btn-dropdown-border: #d9d9d9;

$page-background: #49495010;

$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-900: 900;

$fs-xs: 0.6rem;
$fs-sm: 0.8rem;
$fs-lg: 1.5rem;
$fs-xxl: 2rem;

$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  45: (
    $spacer * 2,
  ),
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 6,
  ),
  9: (
    $spacer * 7,
  ),
  10: (
    $spacer * 8,
  ),
);
