.landing {
  background-color: $page-background;

  .title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
}
