@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/close';

$modal-close-size: 2rem;
$modal-close-lineheight: 1.8rem;
$modal-close-bg: $gray-250;

.modal {
  &-content {
    border: none;
    box-shadow: $box-shadow;
  }

  &-header {
    position: relative;
    border: none;
    padding: 2rem 1rem 3rem 0;

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;

      &:focus {
        outline: transparent;
      }

      span:first-of-type {
        display: flex;
        justify-content: center;
        width: $modal-close-size;
        height: $modal-close-size;
        line-height: $modal-close-lineheight;
        background-color: $modal-close-bg;
        border-radius: $rounded-pill;
        font-weight: normal;
      }
    }
  }

  &-body {
    padding: 0 2rem;

    &.form-submitted {
      text-align: center;
      margin-bottom: 3rem;
    }

    .footer {
      padding-right: 1rem;
    }
  }

  &-footer {
    border: none;
  }
}
