.notfound {
  padding-top: 4rem;
  &-back {
    max-width: 20rem;
    width: 100%;
  }

  .mobile {
    padding-top: 2rem;
    .btn {
      max-width: unset;
    }
  }
}
