@import 'node_modules/bootstrap/scss/badge';

$badge-height: 1.75rem;

.badge {
  display: inline-block;
  height: 1.75rem;
  padding: 0.4rem 1rem;
  border: 1px solid $primary-grey;
  font-size: 0.85rem;
  font-weight: normal;
  color: $primary-grey;
  background: #fff;
}
