@import '../../../assets/scss/component-global';
@import 'node_modules/bootstrap/scss/dropdown';

$dropdown-width: 12.5rem;
$dropdown-flex-width: 100%;

$dropdown-toggle-color: $body-color;

$dropdown-item-color: $text-muted;
$dropdown-item-active-color: $dropdown-toggle-color;

.dropdown {
  &.flex {
    .dropdown-toggle,
    .dropdown-menu {
      width: $dropdown-flex-width !important;
    }
  }

  &-toggle {
    color: $dropdown-toggle-color !important;
    border-radius: $border-radius !important;
    width: $dropdown-width;
    display: flex;
    justify-content: space-between !important;
    border-color: $input-bg !important;
    padding-left: 1.5rem;
    padding-right: 3.5rem !important;
    background: rgba(106, 107, 112, 1) url(../../../assets/images/Chevron.svg)
      no-repeat right 1rem center/1rem 10px;

    &::after {
      content: none;
    }

    &:hover,
    &:focus {
      background-color: $input-hover-bg !important;
      border-color: $input-hover-bg !important;
      box-shadow: none !important;

      &::after {
        color: $primary;
      }
    }

    &:active {
      background-color: $input-focus-bg !important;
      border-color: $input-focus-bg !important;
    }
  }

  &-menu {
    width: $dropdown-width;
    top: -4px !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border: none;
    box-shadow: $box-shadow;

    .dropdown-item {
      color: $dropdown-item-color;

      &:active,
      &.active {
        background-color: $input-hover-bg;
        color: $dropdown-item-active-color;
      }
    }
  }
}
