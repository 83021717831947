#onetrust-consent-sdk {
  #onetrust-banner-sdk div,
  #onetrust-banner-sdk span,
  #onetrust-banner-sdk h1,
  #onetrust-banner-sdk h2,
  #onetrust-banner-sdk h3,
  #onetrust-banner-sdk h4,
  #onetrust-banner-sdk h5,
  #onetrust-banner-sdk h6,
  #onetrust-banner-sdk p,
  #onetrust-banner-sdk img,
  #onetrust-banner-sdk svg,
  #onetrust-banner-sdk button,
  #onetrust-banner-sdk section,
  #onetrust-banner-sdk a,
  #onetrust-banner-sdk label,
  #onetrust-banner-sdk input,
  #onetrust-banner-sdk ul,
  #onetrust-banner-sdk li,
  #onetrust-banner-sdk nav,
  #onetrust-banner-sdk table,
  #onetrust-banner-sdk thead,
  #onetrust-banner-sdk tr,
  #onetrust-banner-sdk td,
  #onetrust-banner-sdk tbody,
  #onetrust-banner-sdk .ot-main-content,
  #onetrust-banner-sdk .ot-toggle,
  #onetrust-banner-sdk #ot-content,
  #onetrust-banner-sdk #ot-pc-content,
  #onetrust-banner-sdk .checkbox,
  #onetrust-pc-sdk div,
  #onetrust-pc-sdk span,
  #onetrust-pc-sdk h1,
  #onetrust-pc-sdk h2,
  #onetrust-pc-sdk h3,
  #onetrust-pc-sdk h4,
  #onetrust-pc-sdk h5,
  #onetrust-pc-sdk h6,
  #onetrust-pc-sdk p,
  #onetrust-pc-sdk img,
  #onetrust-pc-sdk svg,
  #onetrust-pc-sdk button,
  #onetrust-pc-sdk section,
  #onetrust-pc-sdk a,
  #onetrust-pc-sdk label,
  #onetrust-pc-sdk input,
  #onetrust-pc-sdk ul,
  #onetrust-pc-sdk li,
  #onetrust-pc-sdk nav,
  #onetrust-pc-sdk table,
  #onetrust-pc-sdk thead,
  #onetrust-pc-sdk tr,
  #onetrust-pc-sdk td,
  #onetrust-pc-sdk tbody,
  #onetrust-pc-sdk .ot-main-content,
  #onetrust-pc-sdk .ot-toggle,
  #onetrust-pc-sdk #ot-content,
  #onetrust-pc-sdk #ot-pc-content,
  #onetrust-pc-sdk .checkbox,
  #ot-sdk-cookie-policy div,
  #ot-sdk-cookie-policy span,
  #ot-sdk-cookie-policy h1,
  #ot-sdk-cookie-policy h2,
  #ot-sdk-cookie-policy h3,
  #ot-sdk-cookie-policy h4,
  #ot-sdk-cookie-policy h5,
  #ot-sdk-cookie-policy h6,
  #ot-sdk-cookie-policy p,
  #ot-sdk-cookie-policy img,
  #ot-sdk-cookie-policy svg,
  #ot-sdk-cookie-policy button,
  #ot-sdk-cookie-policy section,
  #ot-sdk-cookie-policy a,
  #ot-sdk-cookie-policy label,
  #ot-sdk-cookie-policy input,
  #ot-sdk-cookie-policy ul,
  #ot-sdk-cookie-policy li,
  #ot-sdk-cookie-policy nav,
  #ot-sdk-cookie-policy table,
  #ot-sdk-cookie-policy thead,
  #ot-sdk-cookie-policy tr,
  #ot-sdk-cookie-policy td,
  #ot-sdk-cookie-policy tbody,
  #ot-sdk-cookie-policy .ot-main-content,
  #ot-sdk-cookie-policy .ot-toggle,
  #ot-sdk-cookie-policy #ot-content,
  #ot-sdk-cookie-policy #ot-pc-content,
  #ot-sdk-cookie-policy .checkbox,
  #ot-sync-ntfy div,
  #ot-sync-ntfy span,
  #ot-sync-ntfy h1,
  #ot-sync-ntfy h2,
  #ot-sync-ntfy h3,
  #ot-sync-ntfy h4,
  #ot-sync-ntfy h5,
  #ot-sync-ntfy h6,
  #ot-sync-ntfy p,
  #ot-sync-ntfy img,
  #ot-sync-ntfy svg,
  #ot-sync-ntfy button,
  #ot-sync-ntfy section,
  #ot-sync-ntfy a,
  #ot-sync-ntfy label,
  #ot-sync-ntfy input,
  #ot-sync-ntfy ul,
  #ot-sync-ntfy li,
  #ot-sync-ntfy nav,
  #ot-sync-ntfy table,
  #ot-sync-ntfy thead,
  #ot-sync-ntfy tr,
  #ot-sync-ntfy td,
  #ot-sync-ntfy tbody,
  #ot-sync-ntfy .ot-main-content,
  #ot-sync-ntfy .ot-toggle,
  #ot-sync-ntfy #ot-content,
  #ot-sync-ntfy #ot-pc-content,
  #ot-sync-ntfy .checkbox {
    font-size: 1rem !important;
  }

  h3#ot-category-title,
  h4.ot-cat-header,
  button#accept-recommended-btn-handler,
  button.save-preference-btn-handler.onetrust-close-btn-handler,
  p.ot-acc-grpdesc.ot-category-desc,
  button.ot-link-btn.category-host-list-handler,
  li.ot-host-item,
  li.ot-host-info,
  div#no-results,
  div.ot-fltr-opt,
  div.ot-fltr-btns,
  h3#ot-lst-title span,
  div#ot-pc-desc,
  button.ot-pc-refuse-all-handler {
    line-height: 1.25rem !important;
    font-size: 1rem !important;
  }

  h3#ot-pc-title,
  #onetrust-pc-sdk #ot-pc-title {
    font-size: 1.4rem !important;
  }

  #onetrust-banner-sdk h2,
  #onetrust-pc-sdk h2,
  #ot-sdk-cookie-policy h2 {
    font-size: 1.4rem !important;
  }
}
