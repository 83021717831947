@import '../../../assets/scss/component-global';

$radio-bg-color: $white;
$radio-checked-color: $secondary;

$radio-border: $gray-350;
$radio-border-hover: $gray-350;
$radio-border-checked: $secondary;

$radio-deco-opacity: 0.4;
$radio-deco-opacity-pressed: 0.6;

.radio__wrap {
  padding-right: 0.75rem;

  input[type='radio'] {
    display: none;

    &:hover + span {
      border-color: $radio-border-hover;
    }

    &:checked + span {
      border-color: $radio-border-checked;

      .radio__inner {
        background-color: $secondary;
      }
    }
  }

  .radio {
    transition: $transition-base;
    display: inline-block;
    position: relative;
    background-color: $radio-bg-color;
    border-radius: $rounded-pill;
    height: 1rem;
    min-width: 1rem;
    width: 1rem;
    border: 1px solid $radio-border;
    top: 2px;
    cursor: pointer;

    &:hover .radio__deco {
      opacity: $radio-deco-opacity;
    }

    &:focus .radio__deco,
    &:active .radio__deco {
      opacity: $radio-deco-opacity-pressed;
    }

    &__inner {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background-color: $white;
      border-radius: $rounded-pill;
      transition: $transition-base;
    }

    &__deco {
      position: absolute;
      top: -9px;
      left: -9px;
      width: 32px;
      height: 32px;
      background-color: $gray-350;
      border-radius: $rounded-pill;
      opacity: 0;
      transition: $transition-base;
    }
  }
}
