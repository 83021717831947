.model-list {
  .hero {
    img {
      max-width: 100%;
    }

    .title {
      text-align: end;
    }
  }

  .list-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .list-card {
    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .lease {
      display: flex;
      flex-direction: column;
      align-self: center;
      text-align: end;
    }

    &:last-of-type {
      hr {
        display: none;
      }
    }

    .badge {
      right: 5%;
      top: 1%;
    }

    .model-img {
      cursor: pointer;
      width: 100%;
    }
  }

  img {
    max-width: 80%;
    height: auto;

    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }

  .filters {
    background-color: $page-background;
    margin: 3rem 0;

    .filter-label {
      margin-left: -15px;
      color: $primary-grey;

      svg {
        margin-right: 5px;
        vertical-align: unset;

        &.checked path {
          fill: $primary !important;
        }
      }
    }
  }

  .footer a {
    color: $gray-360;
  }
}
