@import 'node_modules/bootstrap/scss/card';

.card {
  &.model-family-card {
    background-color: transparent;
    border: none;

    :hover {
      cursor: pointer;
    }

    .card-title {
      margin-bottom: 0.5rem;
      color: $secondary;
    }

    .card-body {
      padding: 1.25rem 1.25rem 0 1.25rem;

      p:last-of-type() {
        margin-bottom: 0;
      }

      hr {
        margin-top: 0.25rem;
        margin-bottom: 0;
      }
    }

    .card-img-top {
      max-width: 100%;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
