@import 'rc-slider/assets/index.css';
@import '../../../assets/scss/component-global';

$slider-handle-border: 1px solid $gray-150 !important;
$slider-handle-size: 22px;

$slider-track-bg: $secondary !important;
$slider-track-height: 1px;

.slider {
  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 1rem 0;
  }

  &__values {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    color: $gray-300;
    font-size: $fs-sm;
  }
}

.rc-slider {
  &-track {
    background-color: $slider-track-bg;
    height: $slider-track-height;
  }

  &-rail {
    height: $slider-track-height;
  }

  &-handle {
    margin-top: -11px;
    width: $slider-handle-size;
    height: $slider-handle-size;
    border: $slider-handle-border;
    box-shadow: $box-shadow-sm !important;

    &:active {
      border-color: $gray-250 !important;
    }
  }
}
