@import '../../assets/scss/component-global';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';

$nav-height: 4.375rem;
$nav-border: 1px solid $gray-250;

$nav-logo-wrap-width: 10rem;
$nav-logo-height: 2rem;

$nav-link-color: $text-muted;
$nav-link-color-hover: $primary;

.navbar {
  padding: 0;
  height: $nav-height;
  border-bottom: $nav-border;

  &-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $nav-logo-wrap-width;
    margin: 0;

    img {
      height: $nav-logo-height;
      cursor: pointer;
    }

    &.retailer {
      width: auto;

      .logo-wrap-sm {
        padding: 0.5rem 0rem;
        text-align: center;

        img {
          max-width: 90vw;
        }
      }
    }
  }

  &-nav {
    height: $nav-height;
  }

  .language {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    cursor: pointer;
    border-left: 1px solid $gray-150;
    border-right: 1px solid $gray-150;

    &.with-retailer {
      height: auto;
    }
  }

  &-expand {
    &.sm-retailer-logo {
      justify-content: center;
    }
  }
}

.nav-link {
  display: flex;
  height: 100%;
  align-items: center;
  color: $nav-link-color;

  &:hover {
    color: $nav-link-color-hover;
  }

  &.active {
    position: relative;

    &::after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 4px;
      background-color: $primary;
      top: 0;
      left: 0;
    }
  }
}

.logo-wrap {
  padding: 0.5rem;
  cursor: pointer;
}
