$tab-height: 42px;

.nav-tabs {
  display: inline-flex !important;
  border: none !important;

  .nav-item {
    height: $tab-height;
    border: none !important;
    border-bottom: 1px solid $gray-250 !important;

    &.active {
      &::after {
        top: calc(#{$tab-height} - 4px);
      }
    }
  }
}
