@import '../../../../assets/scss/variables';

.title {
  &-uppercase {
    text-transform: uppercase;
  }

  &-error {
    color: $primary;
  }
}
