@import '../../../assets/scss/component-global';

$checkbox-color-unchecked: $gray-350;
$checkbox-color-checked: $secondary;

$checkbox-deco-opacity: 0.4;
$checkbox-deco-opacity-pressed: 0.6;

$checkbox-margin-r: 0.75rem !important;

input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + span {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 16px;
  height: 16px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid $checkbox-color-unchecked;
  border-radius: $border-radius;
  cursor: pointer;
  transition: $transition-base;
}
input[type='checkbox']:checked + span {
  border-color: $checkbox-color-checked;

  .checkbox__inner {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    background-color: $checkbox-color-checked;
    border-radius: 0.1rem;
  }
}

.checkbox {
  margin-right: $checkbox-margin-r;

  &:hover .checkbox__deco {
    opacity: $checkbox-deco-opacity;
  }
  &:focus .checkbox__deco,
  &:active .checkbox__deco {
    opacity: $checkbox-deco-opacity-pressed;
  }
}

.checkbox__deco {
  position: absolute;
  top: -9px;
  left: -9px;
  width: 32px;
  height: 32px;
  background-color: $checkbox-color-unchecked;
  border-radius: $rounded-pill;
  opacity: 0;
  transition: $transition-base;
}

.checkbox__inner {
  transition: $transition-base;
}

input[type='checkbox'] + span {
  margin-right: 4px;
}
